import { take, call, put, race } from "redux-saga/effects";
import { SENDING_REQUEST, LOGIN_REQUEST, SET_AUTH, LOGOUT, CHANGE_FORM, REQUEST_ERROR } from "../actions/constants";
import { loginUser } from "../Service/calls";
import auth from "../auth";

/**
 * Effect to handle authorization
 * @param  {string} email               The username of the user
 * @param  {string} password               The password of the user
 * @param  {object} options                Options
 */
export function* authorize({ username, password }) {
  yield put({ type: SENDING_REQUEST, sending: true });

  try {
    const data = {
      username,
      password,
    };
    return yield call(loginUser, data);
  } catch (error) {
    yield put({ type: REQUEST_ERROR, error: error.message });
    return false;
  } finally {
    yield put({ type: SENDING_REQUEST, sending: false });
  }
}

export function* logout() {
  yield put({ type: SENDING_REQUEST, sending: true });

  try {
    const response = yield call(auth.logout);
    yield put({ type: SENDING_REQUEST, sending: false });

    return response;
  } catch (error) {
    yield put({ type: REQUEST_ERROR, error: error.message });
  }
}

export function* loginFlow() {
  while (true) {
    const request = yield take(LOGIN_REQUEST);
    const { username, password } = request.data;
    const winner = yield race({
      auth: call(authorize, { username, password }),
      logout: take(LOGOUT),
    });
    console.log("winner.auth :>> ", winner.auth);

    if (winner.auth) {
      yield put({ type: SET_AUTH, newAuthState: true });
      yield put({
        type: CHANGE_FORM,
        newFormState: { username: "", password: "" },
      });
      forwardTo("/dashboard");
    }else{
      yield put({type:'TRIGER_ALERT',alertMessage:'Invalid Credentials',alertType:'error'});
    }
  }
}

export function* logoutFlow() {
  while (true) {
    yield take(LOGOUT);
    yield put({ type: SET_AUTH, newAuthState: false });

    yield call(logout);
    forwardTo("/login");
  }
}

function forwardTo(location) {
  console.log("location :>> ", location);
  window.location.reload(); // Temporary
}
