import { DEV_URL } from "./routes.js";
import api from "./api";

export const getEventDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/getEventDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getOnlineEventDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/getOnlineEventDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};

export const getVenueDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}venue/getVenueDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getLeads = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getLeads`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getContactUsData = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getContactUsList`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getLeadsData = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getLeadData`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getOrganizers = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}organizer/getOrganizers`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getSpeakers = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}speaker/getSpeakers`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getSpeakerDetail = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}speaker/getSpeakerDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};

export const upsertProvider = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/updateUser`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getProviderDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/getUserDetail`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getEvents = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/getAllEvents`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getOtherEvents = async (data) => {
  try{
    /* const testdata = await api.get(`https://eventsinamerica.com/isUserLoggedIn`,{});
    console.log(testdata); */
    const response = await api.post(`${DEV_URL}event/getOtherEvents`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getTopEvents = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/getTopEvents`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getVenues = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}venue/getAllVenues`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getProviders = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/getProviders`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getEventCategories = async (data) => {
  try{
    const response = await api.get(`${DEV_URL}event/getEventCategories`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const upsertVenue = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}venue/upsertVenue`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const upsertEvent = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/upsertEvent`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const upsertPressRelease = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}venue/upsertPressRelease`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const removePressReleaseImage = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}venue/unlinkPressReleaseMedia`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getPressReleaseDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}venue/getPressReleaseDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const upsertOnlineEvent = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/upsertOnlineEvent`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getEventMappedList = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}event/mappedList`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getContactHistory = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getContactHistory`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};

export const getContactHistoryData = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getContactHistoryData`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getEmailDetails = async (data) => {
  try{
    const response = await api.get(`${DEV_URL}email/getEmailDetails`,data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};