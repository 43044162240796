import { DEV_URL } from "./routes.js";
import api from "./api";

export const getMemberList = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}authors/memberList`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getuserList = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/userList`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getPressReleaseList = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/pressReleaseList`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getPaymentUserList = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/paymentUserList`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const updateUserFlag = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/updateUserFlag`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getMemberDetail = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/getUserDetail`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const upsertMember = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}authors/upsertMember`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const upsertInterview = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}authors/upsertInterview`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getInterviewDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}authors/getInterviewDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getInterviews = async (data) => {
  try{
    let qryString = `type=${data.type}`;
    if(data.offset)qryString += `&off=${data.offset}`;
    if(data.totalCount)qryString += `&totalCount=${data.totalCount}`;
    const response = await api.get(`${DEV_URL}authors/author_interviews?${qryString}`);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const scheduleInterview = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}authors/scheduleInterview`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const scheduledInterviews = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}authors/getScheduledInterviews`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const deleteUser = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}user/deleteaccount`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getBotLogList = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getBotLogs`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getBotLogDetails = async (data) => {
  try{
    const response = await api.post(`${DEV_URL}lead/getBotLogDetails`, data);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
export const getBlockedList = async (data) => {
  try{
    const response = await api.get(`${DEV_URL}lead/getBlockedBots`);
    if (response?.status === 200 && response?.data.code === 200) {
      return Promise.resolve(response.data);
    }else{
      return Promise.reject(response.data);
    }
  } catch(e){
    return Promise.reject(e);
  }
};
