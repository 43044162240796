import { combineReducers } from "redux";

import { authentication } from "./auth.reducer";
import { common } from "./common.reducer";
import { member } from "./member.reducer";
import { other } from "./other.reducer";

const rootReducer = combineReducers({
  authentication,
  common,
  member,
  other
});

export default rootReducer;
