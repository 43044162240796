export const CHANGE_FORM = "CHANGE_FORM";
export const SET_AUTH = "SET_AUTH";
export const SENDING_REQUEST = "SENDING_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT = "LOGOUT";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const ADD_BOOK_LIST = "ADD_BOOK_LIST";
export const GET_BOOK_LIST = "GET_BOOK_LIST";
export const BOOK_LIST = "BOOK_LIST";
export const IN_PROGRESS = "IN_PROGRESS";
export const DELETE_BOOK_LIST = "DELETE_BOOK_LIST";
export const BOOK_LIST_DATA = "BOOK_LIST_DATA";
export const BOOK_LIST_BOOKS = "BOOK_LIST_BOOKS";
export const BOOK_LIST_UPDATE = "BOOK_LIST_UPDATE";
export const BOOK_LIST_BOOKS_RES = "BOOK_LIST_BOOKS_RES";
export const BOOK_LIST_DATA_RES = "BOOK_LIST_DATA_RES";
export const ADD_BOOK_TO_LIST = "ADD_BOOK_TO_LIST";
export const DELETE_BOOK_FROM_LIST = "DELETE_BOOK_FROM_LIST";
export const GET_BOOK_DETAILS = "GET_BOOK_DETAILS";
export const BOOK_DETAILS = "BOOK_DETAILS";
export const UPSERT_BOOK = "UPSERT_BOOK";
export const ALERT_CLOSE = "ALERT_CLOSE";
export const TRIGER_ALERT = "TRIGER_ALERT";
export const GET_LIST_CATEGORY = "GET_LIST_CATEGORY";
export const ADD_LIST_CATEGORY = "ADD_LIST_CATEGORY";
export const DELETE_LIST_CATEGORY = "DELETE_LIST_CATEGORY";
export const LIST_CATEGORY_LISTS = "LIST_CATEGORY_LISTS";
export const LIST_CATEGORY_UPDATE = "LIST_CATEGORY_UPDATE";
export const REMOVE_LIST_FROM_CATEGORY = "REMOVE_LIST_FROM_CATEGORY";
export const ADD_LIST_TO_CATEGORY = "ADD_LIST_TO_CATEGORY";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const LIST_CATEGORY_DATA = "LIST_CATEGORY_DATA";
export const LIST_CATEGORY_LISTS_RES = "LIST_CATEGORY_LISTS_RES";
export const LIST_CATEGORY_DATA_RES = "LIST_CATEGORY_DATA_RES";
export const GET_ALL_BOOK_LISTS = "GET_ALL_BOOK_LISTS";
export const ALL_BOOK_LISTS = "ALL_BOOK_LISTS";
export const GET_ALL_LIST_CATEGORY = "GET_ALL_LIST_CATEGORY";
export const ALL_LIST_CATEGORY = "ALL_LIST_CATEGORY";
export const ADD_CATEGORY_TO_LIST = "ADD_CATEGORY_TO_LIST";
export const BOOK_LIST_CATEGORY = "BOOK_LIST_CATEGORY";
export const GET_BOOK_LIST_CATEGORY = "GET_BOOK_LIST_CATEGORY";
export const REMOVE_CATEGORY_FROM_LIST = "REMOVE_CATEGORY_FROM_LIST";
export const BOOK_CATEGORY = "BOOK_CATEGORY";
export const GET_BOOK_CATEGORY = "GET_BOOK_CATEGORY";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const ORDER_LIST = "ORDER_LIST";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const GET_BOOK_STATS = "GET_BOOK_STATS";
export const BOOK_STATS = "BOOK_STATS";
export const GET_BOOK_SEARCH_BY_FIELD = "GET_BOOK_SEARCH_BY_FIELD";
export const BOOK_SEARCH_BY_FIELD = "BOOK_SEARCH_BY_FIELD";
export const GET_SEARCH_BOOK = "GET_SEARCH_BOOK";
export const SEARCH_BOOKS_RESULT = "SEARCH_BOOKS_RESULT";
export const PAGINATION_COUNT = "PAGINATION_COUNT";
export const BULK_INSERT_BOOK_TO_LIST = "BULK_INSERT_BOOK_TO_LIST";
export const RESET_VALUE_BY_VARIABLE = "RESET_VALUE_BY_VARIABLE";
export const BOOK_SEARCH_BY_FIELD_BK_COUNT = "BOOK_SEARCH_BY_FIELD_BK_COUNT";
export const BOOKS_BY_QTY = "BOOKS_BY_QTY";
export const GET_BOOKS_BY_QTY = "GET_BOOKS_BY_QTY";
export const GET_ALL_BISAC_CATEGORY = "GET_ALL_BISAC_CATEGORY"
export const GET_ALL_PRODUCT_CODES = "GET_ALL_PRODUCT_CODES";
export const GET_ALL_PRODUCT_FORMS = "GET_ALL_PRODUCT_FORMS";
export const ALL_BISAC_CATEGORY = "ALL_BISAC_CATEGORY";
export const ALL_PRODUCT_CODES = "ALL_PRODUCT_CODES";
export const ALL_PRODUCT_FORMS = "ALL_PRODUCT_FORMS";
export const GET_BOOKS_COUNT_BY_QTY = "GET_BOOKS_COUNT_BY_QTY";
export const BOOKS_COUNT_BY_QTY = "BOOKS_COUNT_BY_QTY";
export const GET_BOOK_SEARCH = "GET_BOOK_SEARCH";
export const BOOK_SEARCH_RESULT = "BOOK_SEARCH_RESULT";
export const BOOKS_COUNT_BY_BISAC = "BOOKS_COUNT_BY_BISAC";
export const BOOKS_COUNT_BY_PRICE = "BOOKS_COUNT_BY_PRICE";
export const BOOKS_COUNT_BY_DISCOUNT = "BOOKS_COUNT_BY_DISCOUNT";
export const GET_BOOKS_COUNT_BY_BISAC = "GET_BOOKS_COUNT_BY_BISAC";
export const GET_BOOKS_COUNT_BY_PRICE = "GET_BOOKS_COUNT_BY_PRICE";
export const GET_BOOKS_COUNT_BY_DISCOUNT = "GET_BOOKS_COUNT_BY_DISCOUNT";
export const UPDATE_BOOKLIST_PRIORITY = "UPDATE_BOOKLIST_PRIORITY";
export const UPLOAD_BOOKIMAGE = "UPLOAD_BOOKIMAGE";
export const GET_MEMBERS_LIST = "GET_MEMBERS_LIST";
export const MEMBER_LIST_RESULT = "MEMBER_LIST_RESULT";
export const MEMBER_DETAILS = "MEMBER_DETAILS";
export const GET_MEMBER_DETAILS = "GET_MEMBER_DETAILS";
export const UPSERT_MEMBER = "UPSERT_MEMBER";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const USER_LIST_RESULT = "USER_LIST_RESULT";
export const UPDATE_USERS_FLAG = "UPDATE_USERS_FLAG";
export const OTHER_VALUES = "OTHER_VALUES";
export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM";
export const UPSERT_INTERVIEW = "UPSERT_INTERVIEW";
export const GET_INTERVIEW_DETAILS = "GET_INTERVIEW_DETAILS";
export const INTERVIEW_DETAILS = "INTERVIEW_DETAILS";
export const GET_INTERVIEW_LIST = "GET_INTERVIEW_LIST";
export const INTERVIEW_LIST = "INTERVIEW_LIST";
export const EVENT_DETAILS = "EVENT_DETAILS";
export const ONLINE_EVENT_DETAILS = "ONLINE_EVENT_DETAILS";
export const GET_EVENT_DETAILS = "GET_EVENT_DETAILS";
export const GET_ONLINE_EVENT_DETAILS = "GET_ONLINE_EVENT_DETAILS";
export const UPSERT_PROVIDER = "UPSERT_PROVIDER";
export const PROVIDER_DETAILS = "PROVIDER_DETAILS";
export const GET_PROVIDER_DETAILS = "GET_PROVIDER_DETAILS";
export const GET_PROVIDER_LIST = "GET_PROVIDER_LIST";
export const PROVIDER_LIST = "PROVIDER_LIST";
export const GET_EVENT_LIST = "GET_EVENT_LIST";
export const GET_OTHER_EVENT_LIST = "GET_OTHER_EVENT_LIST";
export const GET_TOP_EVENT_LIST = "GET_TOP_EVENT_LIST";
export const GET_VENUE_LIST = "GET_VENUE_LIST";
export const EVENT_LIST = "EVENT_LIST";
export const OTHER_EVENT_LIST = "OTHER_EVENT_LIST";
export const TOP_EVENT_LIST = "TOP_EVENT_LIST";
export const VENUE_LIST = "VENUE_LIST";
export const GET_VENUE_DETAILS = "GET_VENUE_DETAILS";
export const VENUE_DETAILS = "VENUE_DETAILS";
export const GET_LEADS = "GET_LEADS";
export const GET_CONTACT_HISTORY = "GET_CONTACT_HISTORY";
export const GET_CONTACT_US_LIST = "GET_CONTACT_US_LIST";
export const LEADS_LIST = "LEADS_LIST";
export const CONTACT_HISTORY_LIST = "CONTACT_HISTORY_LIST";
export const CONTACT_US_LIST = "CONTACT_US_LIST";
export const GET_LEAD_DATA = "GET_LEAD_DATA";
export const LEAD_DATA = "LEAD_DATA";
export const GET_CONTACT_HISTORY_DATA = "GET_CONTACT_HISTORY_DATA";
export const CONTACT_HISTORY_DATA = "CONTACT_HISTORY_DATA";
export const GET_ORGANIZER_LIST = "GET_ORGANIZER_LIST";
export const ORGANIZERS_LIST = "ORGANIZERS_LIST";
export const GET_SPEAKERS_LIST = "GET_SPEAKERS_LIST";
export const SPEAKERS_LIST = "SPEAKERS_LIST";
export const GET_SPEAKER_DETAILS = "GET_SPEAKER_DETAILS";
export const SPEAKER_DETAILS = "SPEAKER_DETAILS";
export const SCHEDULE_INTERVIEW = "SCHEDULE_INTERVIEW";
export const SCHEDULED_INTERVIEWS = "SCHEDULED_INTERVIEWS";
export const SCHEDULED_INTERVIEW_LIST = "SCHEDULED_INTERVIEW_LIST";
export const SCHEDULED_INTERVIEW_DETAIL = "SCHEDULED_INTERVIEW_DETAIL";
export const UPSERT_VENUE = "UPSERT_VENUE";
export const GET_EVENT_CATEGORIES = "GET_EVENT_CATEGORIES";
export const EVENT_CATEGORIES = "EVENT_CATEGORIES";
export const UPSERT_EVENT = "UPSERT_EVENT";
export const UPSERT_ONLINE_EVENT = "UPSERT_ONLINE_EVENT";
export const EVENT_MAPPED_LIST = "EVENT_MAPPED_LIST";
export const GET_EVENT_MAPPED_LIST = "GET_EVENT_MAPPED_LIST";
export const DELETE_USER = "DELETE_USER";
export const PAYMENT_USER_LIST_RESULT = "PAYMENT_USER_LIST_RESULT";
export const GET_PAYMENT_USERS_LIST = "GET_PAYMENT_USERS_LIST";
export const GET_PRESS_RELEASE_LIST = "GET_PRESS_RELEASE_LIST";
export const PRESS_RELEASE_LIST_RESULT = "PRESS_RELEASE_LIST_RESULT";
export const UPSERT_PRESS_RELEASE = "UPSERT_PRESS_RELEASE";
export const PRESS_RELEASE_DETAILS = "PRESS_RELEASE_DETAILS";
export const GET_PRESS_RELEASE_DETAILS = "GET_PRESS_RELEASE_DETAILS";
export const REMOVE_PRESS_RELEASE_IMAGE = "REMOVE_PRESS_RELEASE_IMAGE";
export const GET_BOT_LOG_LIST = "GET_BOT_LOG_LIST";
export const BOT_LOG_LIST_RESULT = "BOT_LOG_LIST_RESULT";
export const BOT_LOG_DETAILS = "BOT_LOG_DETAILS";
export const GET_BOT_LOG_DETAILS = "GET_BOT_LOG_DETAILS";
export const GET_BLOCKED_LIST = "GET_BLOCKED_LIST";
export const BLOCKED_LIST_RESULT = "BLOCKED_LIST_RESULT";
export const GET_EMAIL_DETAILS = "GET_EMAIL_DETAILS";
export const EMAIL_DETAILS = "EMAIL_DETAILS";