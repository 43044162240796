import { takeLatest, call, put } from "redux-saga/effects";
import { UPSERT_EVENT, GET_EVENT_DETAILS, UPSERT_PROVIDER, GET_PROVIDER_DETAILS, GET_PROVIDER_LIST, GET_EVENT_LIST, GET_VENUE_LIST, GET_VENUE_DETAILS, GET_TOP_EVENT_LIST, GET_LEADS, GET_LEAD_DATA, UPSERT_VENUE, GET_ORGANIZER_LIST, GET_SPEAKERS_LIST, GET_SPEAKER_DETAILS, GET_EVENT_CATEGORIES, GET_ONLINE_EVENT_DETAILS, UPSERT_ONLINE_EVENT, GET_CONTACT_US_LIST, GET_OTHER_EVENT_LIST,GET_EVENT_MAPPED_LIST,UPSERT_PRESS_RELEASE,GET_PRESS_RELEASE_DETAILS,REMOVE_PRESS_RELEASE_IMAGE,GET_CONTACT_HISTORY,GET_CONTACT_HISTORY_DATA,GET_EMAIL_DETAILS } from "../actions/constants";
import { upsertEvent, getEventDetails, getVenueDetails, upsertProvider, getProviderDetails, getEvents, getProviders, getVenues, getTopEvents, getLeads, getLeadsData, getOrganizers, getSpeakers, getSpeakerDetail, getEventCategories,upsertVenue, getOnlineEventDetails, upsertOnlineEvent, getContactUsData, getOtherEvents,getEventMappedList,upsertPressRelease,getPressReleaseDetails,removePressReleaseImage,getContactHistory,getContactHistoryData,getEmailDetails } from "../Service/otherCalls";

function* upsertEventFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertEvent, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: data.msg, alertType: 'success' });
        yield put({ type: 'EVENT_DETAILS', eventDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* upsertOnlineEventFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertOnlineEvent, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: data.msg, alertType: 'success' });
        yield put({ type: 'ONLINE_EVENT_DETAILS', onlineEventDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getEventDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getEventDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'EVENT_DETAILS', eventDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getOnlineEventDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getOnlineEventDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'ONLINE_EVENT_DETAILS', onlineEventDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* upsertProviderFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertProvider, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: data.msg, alertType: 'success' });
        yield put({ type: 'PROVIDER_DETAILS', providerDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getProviderDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getProviderDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'PROVIDER_DETAILS', providerDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getEventListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getEvents, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'EVENT_LIST', eventList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { EVENT_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getOtherEventListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getOtherEvents, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'OTHER_EVENT_LIST', otherEventList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { OTHER_EVENT_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* getTopEventListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getTopEvents, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TOP_EVENT_LIST', topEventList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { TOP_EVENT_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getVenueListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getVenues, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'VENUE_LIST', venueList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { VENUE_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* getVenueDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getVenueDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'VENUE_DETAILS', venueDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getLeadsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getLeads, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'LEADS_LIST', leadsList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { LEADS_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getContactUsListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getContactUsData, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'CONTACT_US_LIST', contactUsList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { CONTACT_US_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* getLeadDataFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getLeadsData, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'LEAD_DATA', leadData: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getOrganizerListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getOrganizers, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'ORGANIZERS_LIST', organizersList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { ORGANIZERS_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getSpeakersListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getSpeakers, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'SPEAKERS_LIST', speakersList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { SPEAKERS_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getSpeakerDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getSpeakerDetail, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'SPEAKER_DETAILS', speakerDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* getProviderListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getProviders, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'PROVIDER_LIST', serviceProviderList: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* upsertVenueFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertVenue, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: data.msg, alertType: 'success' });
        yield put({ type: 'VENUE_DETAILS', eventDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getEventCategoriesFunc(arg) {
    try {
        //yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getEventCategories, arg.data);
        //yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'EVENT_CATEGORIES', eventCategories: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* getEventMappedListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getEventMappedList, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'EVENT_MAPPED_LIST', eventMappedList: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* upsertPressReleaseFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertPressRelease, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: data.msg, alertType: 'success' });
        yield put({ type: 'PRESS_RELEASE_DETAILS', pressReleaseDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* removePressReleaseImageFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(removePressReleaseImage, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: data.msg, alertType: 'success' });
        //yield put({ type: 'PRESS_RELEASE_DETAILS', pressReleaseDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getPressReleaseDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getPressReleaseDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'PRESS_RELEASE_DETAILS', pressReleaseDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}

function* getContactHistoryDataFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getContactHistoryData, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'CONTACT_HISTORY_DATA', contactHistoryData: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getContactHistoryFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getContactHistory, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'CONTACT_HISTORY_LIST', contactHistoryList: data.result });
        yield put({ type: 'PAGINATION_COUNT', paginationTotalCnt: { CONTACT_HISTORY_LIST: data.resultCount } });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getEmailDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getEmailDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'EMAIL_DETAILS', emailDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
export function* otherFlow() {
    yield takeLatest(UPSERT_EVENT, upsertEventFunc);
    yield takeLatest(UPSERT_ONLINE_EVENT, upsertOnlineEventFunc);
    yield takeLatest(GET_EVENT_DETAILS, getEventDetailsFunc);
    yield takeLatest(GET_ONLINE_EVENT_DETAILS, getOnlineEventDetailsFunc);
    yield takeLatest(UPSERT_PROVIDER, upsertProviderFunc);
    yield takeLatest(GET_PROVIDER_DETAILS, getProviderDetailsFunc);
    yield takeLatest(GET_EVENT_LIST, getEventListFunc);
    yield takeLatest(GET_OTHER_EVENT_LIST, getOtherEventListFunc);
    yield takeLatest(GET_TOP_EVENT_LIST, getTopEventListFunc);
    yield takeLatest(GET_VENUE_LIST, getVenueListFunc);
    yield takeLatest(GET_VENUE_DETAILS, getVenueDetailsFunc);
    yield takeLatest(GET_LEADS, getLeadsFunc);
    yield takeLatest(GET_CONTACT_US_LIST, getContactUsListFunc);
    yield takeLatest(GET_LEAD_DATA, getLeadDataFunc);
    yield takeLatest(GET_ORGANIZER_LIST, getOrganizerListFunc);
    yield takeLatest(GET_SPEAKERS_LIST, getSpeakersListFunc);
    yield takeLatest(GET_SPEAKER_DETAILS, getSpeakerDetailsFunc);
    yield takeLatest(GET_PROVIDER_LIST, getProviderListFunc);
    yield takeLatest(UPSERT_VENUE, upsertVenueFunc);
    yield takeLatest(GET_EVENT_CATEGORIES, getEventCategoriesFunc);
    yield takeLatest(GET_EVENT_MAPPED_LIST, getEventMappedListFunc);
    yield takeLatest(UPSERT_PRESS_RELEASE, upsertPressReleaseFunc);
    yield takeLatest(REMOVE_PRESS_RELEASE_IMAGE, removePressReleaseImageFunc);
    yield takeLatest(GET_PRESS_RELEASE_DETAILS, getPressReleaseDetailsFunc);
    yield takeLatest(GET_CONTACT_HISTORY, getContactHistoryFunc);
    yield takeLatest(GET_CONTACT_HISTORY_DATA, getContactHistoryDataFunc);
    yield takeLatest(GET_EMAIL_DETAILS, getEmailDetailsFunc);
    
}
