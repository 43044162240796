const { REACT_APP_API_URL, REACT_APP_ASSET_URL } = process.env;

export const DEV_URL = REACT_APP_API_URL;
export const ASSEST_URL = REACT_APP_ASSET_URL;
export const routes = {
  signup: "user/signup",
  login: "user/login",
  me: "users/me",
  interview: "authorinterview",
};
