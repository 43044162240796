import Axios from "axios";
import { DEV_URL } from "./routes.js";
import Cookies from "js-cookie";

const token = Cookies.get("authToken") ? JSON.parse(Cookies.get("authToken")) : "";
export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  ...(token && { 'Authorization': `Bearer ${token}` })
}
const api = Axios.create({
  baseURL: DEV_URL,
  headers: headers
});

api.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response && error.response.status === 401) {
      // Router.push("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

export const addBearerToken = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};
export const removeBearerToken = () => {
  delete api.defaults.headers.Authorization;
};

export default api;
