import { IN_PROGRESS,ALERT_CLOSE,TRIGER_ALERT,PAGINATION_COUNT,RESET_VALUE_BY_VARIABLE,OTHER_VALUES } from "../actions/constants";

let initialState = {
  inProgress: false,
  alertMessage:'',
  alertType:'error',
  alertOpen:false,
  paginationTotalCnt:{}
};

export function common(state = initialState, action) {
  switch (action.type) {
    case IN_PROGRESS:
      return { ...state,inProgress: action.inProgress };
    case TRIGER_ALERT:
      return { ...state,
        alertMessage: action.alertMessage || '',
        alertType: action.alertType || 'error',
        alertOpen: action.alertOpen || true,
       };
    case ALERT_CLOSE:
      return { ...state,alertOpen: false };
    case PAGINATION_COUNT:
      if(action.paginationTotalCnt){
        const newPaginationItem = { ...state.paginationTotalCnt, ...action.paginationTotalCnt };
        return { ...state,paginationTotalCnt: newPaginationItem };
      }else{
        return { ...state}
      }
      case OTHER_VALUES:
        if(action.otherValues){
          const otherValuesItem = { ...state.otherValues, ...action.otherValues };
          return { ...state,otherValues: otherValuesItem };
        }else{
          return { ...state}
        }
    case RESET_VALUE_BY_VARIABLE:
      return {...state,[action.variableName]:[]}
    default:
      return state;
  }
}
