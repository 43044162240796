import { EVENT_DETAILS, PROVIDER_DETAILS, EVENT_LIST, PROVIDER_LIST, VENUE_LIST, VENUE_DETAILS, TOP_EVENT_LIST, LEADS_LIST, LEAD_DATA, ORGANIZERS_LIST, SPEAKERS_LIST, SPEAKER_DETAILS, EVENT_CATEGORIES, ONLINE_EVENT_DETAILS, CONTACT_US_LIST, OTHER_EVENT_LIST, EVENT_MAPPED_LIST, PRESS_RELEASE_DETAILS,CONTACT_HISTORY_LIST,CONTACT_HISTORY_DATA,EMAIL_DETAILS } from "../actions/constants";
let initialState = {};
export function other(state = initialState, action) {
  switch (action.type) {
    case EVENT_DETAILS:
      return { ...state, eventDetails: action.eventDetails };
    case ONLINE_EVENT_DETAILS:
      return { ...state, onlineEventDetails: action.onlineEventDetails };
    case PROVIDER_DETAILS:
      return { ...state, providerDetails: action.providerDetails };
    case PROVIDER_LIST:
      return { ...state, serviceProviderList: action.serviceProviderList };
    case EVENT_LIST:
      return { ...state, eventList: action.eventList };
    case OTHER_EVENT_LIST:
      return { ...state, otherEventList: action.otherEventList };
    case TOP_EVENT_LIST:
      return { ...state, topEventList: action.topEventList };
    case VENUE_LIST:
      return { ...state, venueList: action.venueList };
    case VENUE_DETAILS:
      return { ...state, venueDetails: action.venueDetails };
    case LEADS_LIST:
      return { ...state, leadsList: action.leadsList };
    case CONTACT_US_LIST:
      return { ...state, contactUsList: action.contactUsList };
    case LEAD_DATA:
      return { ...state, leadData: action.leadData };
    case ORGANIZERS_LIST:
      return { ...state, organizersList: action.organizersList };
    case SPEAKERS_LIST:
      return { ...state, speakersList: action.speakersList };
    case SPEAKER_DETAILS:
      return { ...state, speakerDetails: action.speakerDetails };
    case EVENT_CATEGORIES:
      return { ...state, eventCategories: action.eventCategories };
    case EVENT_MAPPED_LIST:
      return { ...state, eventMappedList: action.eventMappedList };
    case PRESS_RELEASE_DETAILS:
      return { ...state, pressReleaseDetails: action.pressReleaseDetails };
    case CONTACT_HISTORY_LIST:
      return { ...state, contactHistoryList: action.contactHistoryList };
    case CONTACT_HISTORY_DATA:
      return { ...state, contactHistoryData: action.contactHistoryData };
      case EMAIL_DETAILS:
        return { ...state, emailDetails: action.emailDetails };
    default:
      return state;
  }
}
