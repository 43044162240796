import { takeLatest, call, put } from "redux-saga/effects";
import { GET_MEMBERS_LIST,MEMBER_LIST_RESULT,GET_MEMBER_DETAILS,UPSERT_MEMBER,GET_USERS_LIST,USER_LIST_RESULT,UPDATE_USERS_FLAG,UPSERT_INTERVIEW,GET_INTERVIEW_DETAILS,GET_INTERVIEW_LIST,SCHEDULE_INTERVIEW,SCHEDULED_INTERVIEWS,DELETE_USER,GET_PAYMENT_USERS_LIST,PAYMENT_USER_LIST_RESULT,GET_PRESS_RELEASE_LIST,PRESS_RELEASE_LIST_RESULT, GET_BOT_LOG_LIST, BOT_LOG_LIST_RESULT, GET_BOT_LOG_DETAILS, BOT_LOG_DETAILS,GET_BLOCKED_LIST,BLOCKED_LIST_RESULT } from "../actions/constants";
import { getMemberList,getMemberDetail,upsertMember,getuserList,updateUserFlag,upsertInterview,getInterviewDetails,getInterviews,scheduleInterview,scheduledInterviews,deleteUser,getPaymentUserList,getPressReleaseList,getBotLogList,getBotLogDetails,getBlockedList } from "../Service/memberCalls";
function* getMemberListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getMemberList, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: MEMBER_LIST_RESULT, membersList: data.result });
        yield put({type:'PAGINATION_COUNT',paginationTotalCnt:{MEMBER_LIST_RESULT:data.resultCount}});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getMemberDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getMemberDetail, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'MEMBER_DETAILS', memberDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* addEditMemberFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertMember, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'MEMBER_DETAILS', memberDetails: data.result });
        yield put({type:'TRIGER_ALERT',alertMessage:data.msg,alertType:'success'});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* upsertInterviewFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(upsertInterview, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({type:'TRIGER_ALERT',alertMessage:data.msg,alertType:'success'});
        yield put({ type: 'INTERVIEW_DETAILS', interviewDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getuserListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getuserList, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: USER_LIST_RESULT, usersList: data.result });
        yield put({type:'PAGINATION_COUNT',paginationTotalCnt:{USER_LIST_RESULT:data.resultCount}});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getPressReleaseListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getPressReleaseList, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: PRESS_RELEASE_LIST_RESULT, pressReleaseList: data.result });
        yield put({type:'PAGINATION_COUNT',paginationTotalCnt:{PRESS_RELEASE_LIST_RESULT:data.resultCount}});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getPaymentuserListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getPaymentUserList, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: PAYMENT_USER_LIST_RESULT, paymentUsersList: data.result });
        yield put({type:'PAGINATION_COUNT',paginationTotalCnt:{PAYMENT_USER_LIST_RESULT:data.resultCount}});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* updateUserFlagFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(updateUserFlag, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({type:'TRIGER_ALERT',alertMessage:data.msg,alertType:'success'});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getInterviewDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getInterviewDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'INTERVIEW_DETAILS', interviewDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getInterviewsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getInterviews, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'INTERVIEW_LIST', interviewLists: data.result });
        yield put({type:'PAGINATION_COUNT',paginationTotalCnt:{INTERVIEW_LIST:data.resultCount}});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* scheduleInterviewFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(scheduleInterview, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({type:'TRIGER_ALERT',alertMessage:data.msg,alertType:'success'});
        yield put({ type: 'SCHEDULED_INTERVIEW_LIST', scheduledInterviewList: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* scheduledInterviewsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(scheduledInterviews, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'SCHEDULED_INTERVIEW_LIST', scheduledInterviewList: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* deleteUserFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(deleteUser, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({type:'TRIGER_ALERT',alertMessage:data.msg,alertType:'success'});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getBotLogListFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getBotLogList, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: BOT_LOG_LIST_RESULT, botLogList: data.result });
        yield put({type:'PAGINATION_COUNT',paginationTotalCnt:{BOT_LOG_LIST_RESULT:data.resultCount}});
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getBlockedListFunc(arg) {
    try {
        /* yield put({ type: 'IN_PROGRESS', inProgress: true }); */
        const data = yield call(getBlockedList, arg.data);
        /* yield put({ type: 'IN_PROGRESS', inProgress: false }); */
        let blockedList = [];
        if (data?.result?.length) {
            blockedList = data.result.map(item => item.name);
        }
        yield put({ type: BLOCKED_LIST_RESULT, blockedList: blockedList });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
function* getBotLogDetailsFunc(arg) {
    try {
        yield put({ type: 'IN_PROGRESS', inProgress: true });
        const data = yield call(getBotLogDetails, arg.data);
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: BOT_LOG_DETAILS, botLogDetails: data.result });
    } catch (e) {
        yield put({ type: 'IN_PROGRESS', inProgress: false });
        yield put({ type: 'TRIGER_ALERT', alertMessage: e.msg || 'Error Occured', alertType: 'error' });
        console.log(e);
    }
}
export function* memberFlow() {
    yield takeLatest(GET_MEMBERS_LIST, getMemberListFunc);
    yield takeLatest(GET_MEMBER_DETAILS, getMemberDetailsFunc);
    yield takeLatest(UPSERT_MEMBER, addEditMemberFunc);
    yield takeLatest(GET_USERS_LIST, getuserListFunc);
    yield takeLatest(UPDATE_USERS_FLAG, updateUserFlagFunc);
    yield takeLatest(UPSERT_INTERVIEW, upsertInterviewFunc);
    yield takeLatest(GET_INTERVIEW_DETAILS, getInterviewDetailsFunc);
    yield takeLatest(GET_INTERVIEW_LIST, getInterviewsFunc);
    yield takeLatest(SCHEDULE_INTERVIEW, scheduleInterviewFunc);
    yield takeLatest(SCHEDULED_INTERVIEWS, scheduledInterviewsFunc);
    yield takeLatest(DELETE_USER, deleteUserFunc);
    yield takeLatest(GET_PAYMENT_USERS_LIST, getPaymentuserListFunc);
    yield takeLatest(GET_PRESS_RELEASE_LIST, getPressReleaseListFunc);
    yield takeLatest(GET_BOT_LOG_LIST, getBotLogListFunc);
    yield takeLatest(GET_BOT_LOG_DETAILS, getBotLogDetailsFunc);
    yield takeLatest(GET_BLOCKED_LIST, getBlockedListFunc);

}