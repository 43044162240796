import React from "react";

const footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="container">
          <div className="row f_row">
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Event Manager</h6>
              <ul className="f_link">
                <li>
                  <a href="#">List Your Events</a>
                </li>
                <li>
                  <a href="#">Call for Speakers</a>
                </li>
                <li>
                  <a href="#">Agenda</a>
                </li>
                <li>
                  <a href="#">Lead Generator</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Supplier</h6>
              <ul className="f_link">
                <li>
                  <a href="#">List Your Services</a>
                </li>
                <li>
                  <a href="#">Lead Generator</a>
                </li>
                <li>
                  <a href="#">Supplier Directory</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Attendee</h6>
              <ul className="f_link">
                <li>
                  <a href="https://beta.eventsinamerica.com/event-marketing-lead-generation">Profile</a>
                </li>
                <li>
                  <a href="https://beta.eventsinamerica.com/event-marketing-lead-generation">My Show Calendar</a>
                </li>
                <li>
                  <a href="https://beta.eventsinamerica.com/event-marketing-lead-generation">Event Updates</a>
                </li>
                <li>
                  <a href="https://beta.eventsinamerica.com/event-marketing-lead-generation">Networking Tools</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Advertise</h6>
              <ul className="f_link">
                <li>
                  <a href="#">Sponsorship</a>
                </li>
                <li>
                  <a href="#">Featured Listing</a>
                </li>
                <li>
                  <a href="#">Newsletter Listing</a>
                </li>
                <li>
                  <a href="#">Exhibit Display Marketplace</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Venue Manager</h6>
              <ul className="f_link">
                <li>
                  <a href="#">Venue Profile</a>
                </li>
                <li>
                  <a href="#">Event Calendar</a>
                </li>
                <li>
                  <a href="#">RFP</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Speaker</h6>
              <ul className="f_link">
                <li>
                  <a href="#">Profile</a>
                </li>
                <li>
                  <a href="#">Lead Generator</a>
                </li>
                <li>
                  <a href="#">Call for Speakers</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Exhibitor</h6>
              <ul className="f_link">
                <li>
                  <a href="#">Profile</a>
                </li>
                <li>
                  <a href="#">Trade Show Calendar</a>
                </li>
                <li>
                  <a href="#">Product Showcase</a>
                </li>
                <li>
                  <a href="#">Appointment Scheduler</a>
                </li>
                <li>
                  <a href="#">Booth Marketplace</a>
                </li>
                <li>
                  <a href="#">Lead Generator</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h6>Newsletter</h6>
              <ul className="f_link">
                <li>
                  <a href="https://beta.eventsinamerica.com/upcoming-trade-show-calendar-update-alert">
                    Weekly Trade Show Calendar
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <form className="footerSignUpForm">
                <div className="">
                  <input type="text" name="" placeholder="Email" />
                  <p>Bi - Weekly Event Calender</p>
                </div>
                <div className="btn_c">
                  {" "}
                  <button type="button" className="newBTN newBorderBTN">
                    Sign Up
                  </button>{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomContainer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer_Bottom">
                <ul className="f_link footNev">
                  <li>
                    <a href="https://eventsinamerica.com/top-trade-shows-conferences-consumer-shows-about-us">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="https://eventsinamerica.com/event-list-convention-and-conference-center-list-api-contact-us">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="https://eventsinamerica.com/trade-show-display-makers-exhibitors-suppliers-user-agreement">
                      User Agreement
                    </a>
                  </li>
                  <li>
                    <a href="https://eventsinamerica.com/trade-show-services-suppliers-directory-list-subscription-plan">
                      Subscribe
                    </a>
                  </li>
                  <li>
                    <a href="https://eventsinamerica.com/trade-show-contact-list-payment">Payment</a>
                  </li>
                  <li>
                    <a href="https://eventsinamerica.com/event-marketing-lead-generation">Advertise</a>
                  </li>
                </ul>
                <p>© Since 2008 EventsInAmerica.com All Rights Reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
