import { MEMBER_LIST_RESULT, MEMBER_DETAILS, RESET_VALUE_BY_VARIABLE, USER_LIST_RESULT, INTERVIEW_DETAILS, INTERVIEW_LIST, SCHEDULED_INTERVIEW_LIST, SCHEDULED_INTERVIEW_DETAIL, PAYMENT_USER_LIST_RESULT, PRESS_RELEASE_LIST_RESULT, BOT_LOG_DETAILS, BOT_LOG_LIST_RESULT,BLOCKED_LIST_RESULT } from "../actions/constants";
let initialState = {};
export function member(state = initialState, action) {
  switch (action.type) {
    case MEMBER_LIST_RESULT:
      return { ...state, membersList: action.membersList };
    case MEMBER_DETAILS:
      return { ...state, memberDetails: action.memberDetails };
    case INTERVIEW_DETAILS:
      return { ...state, interviewDetails: action.interviewDetails };
    case INTERVIEW_LIST:
      return { ...state, interviewLists: action.interviewLists };
    case RESET_VALUE_BY_VARIABLE:
      return { ...state, [action.variableName]: [] }
    case USER_LIST_RESULT:
      return { ...state, usersList: action.usersList };
    case PAYMENT_USER_LIST_RESULT:
      return { ...state, paymentUsersList: action.paymentUsersList };
    case SCHEDULED_INTERVIEW_LIST:
      return { ...state, scheduledInterviewList: action.scheduledInterviewList };
    case SCHEDULED_INTERVIEW_DETAIL:
      return { ...state, scheduledInterviewDetails: action.scheduledInterviewDetails };
    case PRESS_RELEASE_LIST_RESULT:
      return { ...state, pressReleaseList: action.pressReleaseList };
    case BOT_LOG_DETAILS:
      return { ...state, botLogDetails: action.botLogDetails };
    case BOT_LOG_LIST_RESULT:
      return { ...state, botLogList: action.botLogList };
    case BLOCKED_LIST_RESULT:
      return { ...state, blockedList: action.blockedList };
    default:
      return state;
  }
}
