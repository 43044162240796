import React from "react";
import Cookies from 'js-cookie';

const Header = (props) => {

  const logOut = () => {
    Cookies.remove('authToken')
    window.location.reload();
  };

  return (
    <header className="header">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg" style={{ alignItems: 'flex-end',padding:"12px" }}>
          <a className="navbar-brand" href="/">
            <img src="/images/logo.png" />
          </a>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            data-target="#navb">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="mainHead_">
            <div className="collapse navbar-collapse" id="navb">
              <ul className="navbar-nav">
                <li className="nav-item active" style={{ margin: '2px', padding: '4px' }}>
                  <a className="loginbtn pointer" onClick={logOut}> Log Out</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
