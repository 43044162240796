import Cookies from 'js-cookie';
let localStorage;

// If we're testing, use a local storage polyfill
if (global.process && process.env.NODE_ENV === "test") {
  localStorage = require("localStorage");
} else {
  // If not, use the browser one
  localStorage = global.window.localStorage;
}

const auth = {
  /**
   * Checks if a user is logged in
   */
  loggedIn() {
    return !!Cookies.get("authToken");
  },

  logout() {
    console.log("localStorage :>> ");
    localStorage.removeItem("admintoken");
    return true;
  },
};

export default auth;
