import axios from "axios";
import { DEV_URL } from "./routes.js";
import { addBearerToken } from './api'
import Cookies from "js-cookie";

export const loginUser = async (data) => {
  const response = await axios.post(`${DEV_URL}user/loginAdmin`, data);
  if (response?.status === 200 && response?.data.code === 200) {
    Cookies.set("authToken", JSON.stringify(response?.data?.token));
    addBearerToken(response?.data?.token);
    return Promise.resolve(true);
  } else {
    return Promise.resolve(false);
  }
};
